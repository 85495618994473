
import { computed, defineComponent, ref } from "vue";
import { useGlobalLookupStore } from "@/store/pinia/application/useGlobalLookupStore";
import { Option } from "@/core/types/misc/Option";

export default defineComponent({
  name: "Input",
  setup(props, { emit }) {
    const lookupStore = useGlobalLookupStore();
    const formValue = ref("");
    const selCountry = ref("");
    const parsePhone = () => {
      const phone = props.modelValue || "";
      const area =
        countries.value.find((item) => {
          if (item) return phone.startsWith(item.value);
        }) || ({} as Option);
      selCountry.value = area?.value || "";
      formValue.value = phone.substring(selCountry.value.length);
    };
    const handleTextChange = (val) => {
      formValue.value = val;
      emit("update:modelValue", val);
    };
    const handlePhoneChange = (val) => {
      const endValue = `${selCountry.value}${val}`;
      formValue.value = `${val.replace(/[^0-9]/, "")}`;
      emit("update:modelValue", endValue);
    };
    const handleNumberChange = (val) => {
      formValue.value = `${val.replace(/[^0-9]/, "")}`;
      emit("update:modelValue", val);
    };
    const countries = computed(() => {
      return lookupStore.countries;
    });
    return {
      handleTextChange,
      handlePhoneChange,
      handleNumberChange,
      formValue,
      countries,
      selCountry,
      parsePhone,
      lookupStore,
    };
  },
  async mounted() {
    await this.lookupStore.getLookupCountry();
  },
  updated() {
    this.parsePhone();
  },
  props: {
    modelValue: {
      type: String,
      required: true,
      default: "",
    },
    type: {
      type: String,
      required: true,
    },
    length: {
      type: String,
      required: false,
    },
    label: {
      type: String,
      required: true,
    },
    placeHolder: {
      type: String,
      default: "",
      required: false,
    },
  },
});
