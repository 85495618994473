<template>
  <el-form-item class="m-0 gws-form">
    <template #label>
      <span>{{ label }}</span>
    </template>
    <template v-if="type === 'text'">
      <el-input
        :placeholder="placeHolder"
        @input="handleTextChange($event)"
        :maxlength="length"
        v-model="formValue"
      ></el-input>
    </template>
    <template v-if="type === 'phone'">
      <div class="d-flex phone-group">
        <div>
          <el-select
            class="country-select"
            v-model="selCountry"
            placeholder="Negara"
            clearable
            filterable
          >
            <el-option
              v-for="item in countries"
              :key="item.label"
              :label="item.label"
              :value="item.value"
            >
              <div class="d-flex justify-content-between">
                <span>{{ item.label }}</span>
                <span>{{ item.value }}</span>
              </div>
            </el-option>
            <template v-if="selCountry" #default>
              {{ selCountry }}
            </template>
          </el-select>
        </div>
        <div>
          <el-input
            class="phone-input"
            :placeholder="placeHolder"
            @input="handlePhoneChange($event)"
            :maxlength="length"
            v-model="formValue"
          ></el-input>
        </div>
      </div>
    </template>
    <template v-if="type === 'email'"
      ><el-input
        :placeholder="placeHolder"
        @input="handleTextChange($event)"
        v-model="formValue"
        :maxlength="length"
        type="email"
      ></el-input
    ></template>
    <template v-if="type === 'number'">
      <el-input
        :placeholder="placeHolder"
        @input="handleNumberChange($event)"
        :maxlength="length"
        v-model="formValue"
      ></el-input
    ></template>
  </el-form-item>
</template>
<script lang="ts">
import { computed, defineComponent, ref } from "vue";
import { useGlobalLookupStore } from "@/store/pinia/application/useGlobalLookupStore";
import { Option } from "@/core/types/misc/Option";

export default defineComponent({
  name: "Input",
  setup(props, { emit }) {
    const lookupStore = useGlobalLookupStore();
    const formValue = ref("");
    const selCountry = ref("");
    const parsePhone = () => {
      const phone = props.modelValue || "";
      const area =
        countries.value.find((item) => {
          if (item) return phone.startsWith(item.value);
        }) || ({} as Option);
      selCountry.value = area?.value || "";
      formValue.value = phone.substring(selCountry.value.length);
    };
    const handleTextChange = (val) => {
      formValue.value = val;
      emit("update:modelValue", val);
    };
    const handlePhoneChange = (val) => {
      const endValue = `${selCountry.value}${val}`;
      formValue.value = `${val.replace(/[^0-9]/, "")}`;
      emit("update:modelValue", endValue);
    };
    const handleNumberChange = (val) => {
      formValue.value = `${val.replace(/[^0-9]/, "")}`;
      emit("update:modelValue", val);
    };
    const countries = computed(() => {
      return lookupStore.countries;
    });
    return {
      handleTextChange,
      handlePhoneChange,
      handleNumberChange,
      formValue,
      countries,
      selCountry,
      parsePhone,
      lookupStore,
    };
  },
  async mounted() {
    await this.lookupStore.getLookupCountry();
  },
  updated() {
    this.parsePhone();
  },
  props: {
    modelValue: {
      type: String,
      required: true,
      default: "",
    },
    type: {
      type: String,
      required: true,
    },
    length: {
      type: String,
      required: false,
    },
    label: {
      type: String,
      required: true,
    },
    placeHolder: {
      type: String,
      default: "",
      required: false,
    },
  },
});
</script>
<style lang="scss" scoped>
.phone-group {
  padding: 0 15px;
  border: 1px solid #dcdfe6;
  border-radius: 4px;
}
.country-select {
  width: 80px;
  :deep(.select-trigger) {
    .el-input {
      .el-input__inner {
        padding: 0;
        border: none;
      }
    }
  }
}
.phone-input {
  width: 100%;
  :deep(.el-input__inner) {
    padding: 0;
    border: none;
  }
}
</style>
